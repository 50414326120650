


























































































































import BopService from "@/services/BopService";
import BopArchiveService from "@/services/BopArchiveService";
import {
  BopModelEnum,
  BopStatus,
  CoefficientTypeEnum,
  OperationType,
} from "@/utils/Enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Guid } from "guid-typescript";
import Bop from "@/models/Bop";
import OperationGroup from "@/models/OperationGroup";
import Validation from "@/models/Validation";
import BopModelService from "@/services/BopModelService";
import BopStatusService from "@/services/BopStatusService";
import table from "@/utils/table";
import BopOperation from "@/models/BopOperation";
import CoefficientService from "@/services/CoefficientService";
import SitesServices from "@/services/SitesServices";
import Constants from "@/utils/Constants";
import SapApiService from "@/services/SapApiService";
import TypeTravauxService from "@/services/TypeTravauxService";
import router from "@/router";
import User from "@/models/User";
import userService from "@/services/UserService";
import { UserRoleEnum, UserProfilEnum } from "@/utils/Enums";

type requiredField = {
  translationKey: string;
  elementKey: string;
  isRequiredOnSimulation: boolean;
  validation: string | null;
};
type step = {
  slot: string;
  title: string;
  tooltip?: string;
  requiredFieldsKeys: Array<requiredField>;
};
const FAMILY_GROUPED_BOP_MODELS: Array<string> = [
  BopModelEnum.Echafaudage,
  BopModelEnum.PeintureIndustrielle,
  BopModelEnum.NettoyageIndustrielle,
];
const VALIDATION_FORMULAS = {
  strictPositifNumber: "{p}",
};
@Component({
  components: {
    Wizard: () => import("@/components/Shared/wizard/Wizard.vue"),
    GeneralInformationsStep: () =>
      import("@/components/BopSetup/GeneralInformationsStep.vue"),
    OperationDiverseStep: () =>
      import("@/components/BopSetup/OperationDiverseStep.vue"),
    OperationPrincipalStep: () =>
      import("@/components/BopSetup/OperationPrincipalStep.vue"),
    OperationPrincipalStepFile: () =>
      import("@/components/BopSetup/OperationPrincipalStepFile.vue"),
    OperationPrincipalStepOpFamily: () =>
      import("@/components/BopSetup/OperationPrincipalStepOpFamily.vue"),
    OperationPrincipalCalAppareilStep: () =>
      import(
        "@/components/BopSetup/CalorifugeageAppareil/OperationPrincipalCaStep.vue"
      ),
    OperationSuppStep: () =>
      import("@/components/BopSetup/OperationSuppStep.vue"),
    BopSummaryStep: () => import("@/components/BopSetup/BopSummaryStep.vue"),
    FacturationStep: () => import("@/components/BopSetup/FacturationStep.vue"),
    FebStep: () => import("@/components/BopSetup/FebStep.vue"),
    OperationPrincipalCalTuyStep: () =>
      import(
        "@/components/BopSetup/CalorifugeageTuyauterie/OperationPrincipalCalTuyStep.vue"
      ),
  },
})
export default class BopSetup extends Vue {
  private saveFinished: boolean = true;

  private wizardKey: string = Guid.create().toString();
  private saveButtonText: string = "";
  private stepIndex: number = 0;
  private startIndex: number = 0;
  private isLoaded: boolean = false;
  private isVisible: boolean = false;
  private saveDisabled: boolean = false;
  private steps: Array<step> = [];
  private isOperationStepLoaded: boolean = false;
  private operationPrinciaplLoaded = false;
  private caloTuyauterieOperations: Array<any> = [];
  private isGeneralStepLoaded = false;
  private isExportingPdf: boolean = false; // State to manage loading spinner
  private typeTravaux: Array<any> = [];
  private currentUser: User = userService.getCurrentuser();
  private userCanApproveBop: boolean = false;

  private onFullyLoaded() {
    this.isOperationStepLoaded = true;
    setTimeout(() => {
      this.$store.commit("changeHasBeenModified", false);
    }, 400);
  }
  private get language(): string {
    return this.$i18n.locale;
  }
  private isSaveVisible = true;
  private get haveFamilyGrouping() {
    return FAMILY_GROUPED_BOP_MODELS.includes(this.bop.bopModelKey);
  }
  private get sendDisabled(): boolean {
    //Le bouton [envoyer vers Arkema] reste disponible dans le processus 2:
    // tant qu’un BOP est en statut « en attente » il est possible à tout moment de le modifier et de le renvoyer.
    if (
      this.bop.isCreatedBySap &&
      this.summaryStepIndex !== this.stepIndex &&
      this.bop.bopStatusKey === BopStatus.WaitingForValidation
    ) {
      return true;
    }
    if (
      (this.bop.totalOperationSuppHeurePoints +
        this.bop.totalSuppHauteurPoints ===
        0 ||
        this.facturationValidation.sapSupplementServiceNumber.length) &&
      //epi validation
      (this.bop.totalOperationSuppEpiPoints === 0 ||
        this.facturationValidation.sapEPIServiceNumber.length) &&
      //general service validation
      (this.bop.totalOperationPrincipalPoints +
        this.bop.totalOperationDiversePoints ===
        0 ||
        this.facturationValidation.sapPrestationServiceNumber.length)
    ) {
      return false;
    } else {
      if (this.bop.isCreatedBySap && this.summaryStepIndex === this.stepIndex)
        return true;
    }

    return false;
  }
  private get isCalorifugeageAppareilBop() {
    return this.bop.bopModelKey === BopModelEnum.CalorifugageAppareil;
  }
  private get isCalorifugeageTuyauterieBop() {
    return this.bop.bopModelKey === BopModelEnum.CalorifugageTuyauterie;
  }
  private get bop(): Bop {
    return this.$store.state.bop;
  }
  private get isSimulation(): boolean {
    return this.bop.bopStatusKey === BopStatus.Simulation;
  }
  private get isEditable(): boolean {
    return !Bop.readOnlyStatuses.includes(this.bop.bopStatusKey);
  }
  private get isAwaitingApproval(): boolean {
    return this.bop.bopStatusKey === BopStatus.AwaitingLaunchWorkApproval;
  }
  private get isEdit(): boolean {
    return (
      this.bop.bopStatusKey === BopStatus.Finished &&
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private get summaryStepIndex(): number {
    return this.steps.findIndex((s) => s.slot === "summary");
  }
  private get facturationStepIndex(): number {
    return this.steps.findIndex(
      (s) => s.slot === this.facturationStepConfig.slot
    );
  }
  private get canValidateBop(): boolean {
    return !this.isSimulation && this.stepIndex === this.summaryStepIndex;
  }
  private operationPrincipalGroups: Array<any> = [];
  private get facturationStepConfig(): step {
    return {
      slot: "facturation",
      title: this.$t("bop.facturation.stepTitle").toString(),
      requiredFieldsKeys: [],
    };
  }
  private get facturationSapStepConfig(): step {
    return {
      slot: "facturationSap",
      title: this.$t("bop.facturationSap.stepTitle").toString(),
      requiredFieldsKeys: [],
    };
  }
  private get febStepConfig(): step {
    return {
      slot: "feb",
      title: this.$t("bop.feb.stepTitle").toString(),
      tooltip: this.$t("bop.feb.stepTooltip").toString(),
      requiredFieldsKeys: [
        {
          translationKey: "bopSetup.feb.nombrePlancherTravail",
          elementKey: "feb.nombrePlancherTravail",
          isRequiredOnSimulation: false,
          validation: VALIDATION_FORMULAS.strictPositifNumber,
        },
        {
          translationKey: "bopSetup.feb.chargeSpecifiquePlanchers",
          elementKey: "feb.chargeSpecifiquePlanchers",
          isRequiredOnSimulation: false,
          validation: VALIDATION_FORMULAS.strictPositifNumber,
        },
      ],
    };
  }
  private get facturationValidation(): Validation {
    return this.$store.state.validation;
  }
  private get isCreatedBySapAndEditable() {
    return (
      this.bop.isCreatedBySap &&
      (<Array<string>>[
        BopStatus.Initial,
        BopStatus.Simulation,
        BopStatus.WaitingForValidation,
      ]).includes(this.bop.bopStatusKey)
    );
  }
  private get isTuyauterieMetalique(): boolean {
    if (this.bop.bopModelKey === BopModelEnum.TuyauterieMetalique) return true;
    else return false;
  }
  /**
   * #######
   * Methods
   * #######
   */
  private refreshWizard() {
    this.wizardKey = Guid.create().toString();
  }
  private async onBopModelChanged(bm: any) {
    if (!this.isEditable) return;
    if (this.bop.bopModelId === null) {
      this.bop.bopModelId = bm.bopModel.id;
      this.bop.bopModelName = bm.translation.name;
      this.$store.commit("changeSelectedBopModel", bm.bopModel);
      this.bop.bopModelKey = bm.bopModel.key;
    } else {
      const modalResponse = await this.$bvModal.msgBoxConfirm(
        this.$t("bopConfirmChangeBopModel.message").toString(),
        {
          title: this.$t("bopConfirmChangeBopModel.title").toString(),
          size: "lg",
          buttonSize: "lg",
          okVariant: "primary",
          cancelVariant: "secondary",
          okTitle: this.$t("bopConfirmChangeBopModel.yes").toString(),
          cancelTitle: this.$t("bopConfirmChangeBopModel.no").toString(),
          footerClass: "p-2",
          hideHeaderClose: false,
          dialogClass: "modal-app",
          centered: true,
        }
      );
      if (modalResponse) {
        this.bop.bopModelId = bm.bopModel.id;
        this.bop.bopModelName = bm.translation.name;
        this.$store.commit("changeSelectedBopModel", bm.bopModel);
        this.bop.bopModelKey = bm.bopModel.key;
        this.bop.operationPrincipalGroups = [];
        this.bop.operationDiverseGroups = [new OperationGroup()];
        this.bop.operationSuppGroups = [new OperationGroup()];
        this.bop.operationPrincipalGroups.forEach(
          (og: OperationGroup) => (og.canBeDeleted = true)
        );
        this.bop.selectedGlobalCoefficients = {};
        this.$store.commit("changeTotalOperationPrincipalPoints", 0);
        this.$store.commit("changeTotalOperationSuppEpiPoints", 0);
        this.$store.commit("changeTotalOperationSuppHeurePoints", 0);
        this.$store.commit("changetotalSuppHauteurPointsPoints", 0);
        this.$store.commit("changeTotalOperationDiversePoints", 0);
        this.$store.commit("changeSelectedGlobalCoefficients", {});
        this.globalCoefficients = (
          await CoefficientService.getCoefficientByTypeAndBopModel(
            bm.bopModel.id,
            CoefficientTypeEnum.T4,
            this.language
          )
        ).data;
      }
    }
  }
  private getBopValue(path: string) {
    return table.findByPath(this.bop, path);
  }
  private evaluateValidationFormula(validationFormula, fieldValue) {
    switch (validationFormula) {
      case VALIDATION_FORMULAS.strictPositifNumber:
        return parseFloat(fieldValue) > 0;
    }
    return new RegExp(validationFormula).test(fieldValue);
  }
  private validateStep(stepIndex, showNotification = true): boolean {
    const fieldsToValidate = this.steps[stepIndex].requiredFieldsKeys;
    for (let i = 0; i < fieldsToValidate.length; i++) {
      const field = fieldsToValidate[i];
      const fieldValue = this.getBopValue(field.elementKey);
      const isAlwaysRequired =
        (this.isSimulation && field.isRequiredOnSimulation) ||
        !this.isSimulation;
      if (
        isAlwaysRequired &&
        (!fieldValue ||
          (field.validation &&
            !this.evaluateValidationFormula(field.validation, fieldValue)))
      ) {
        if (showNotification) {
          this.notify(
            this.$t("bopSetup.validationErrorTitle").toString(),
            this.$t("bopSetup.validationErrorText").toString() +
              " " +
              this.$t(field.translationKey).toString(),
            "error"
          );
        }
        return false;
      }
    }
    return true;
  }
  private clearNotifications() {
    this.$notify({
      group: "globalError",
      clean: true,
    });
  }
  private setNavigationBehavour(stepIndex: number, nextStepIndex: number) {
    const defaultText = this.$t("wizard.save").toString();
    if (this.canValidateBop) {
      this.saveButtonText = this.$t("wizard.validate").toString();
    } else {
      this.saveButtonText = defaultText;
    }
  }

  private next(stepIndex: number, nextStepIndex: number) {
    this.stepIndex = nextStepIndex;
    for (let i = stepIndex; i < nextStepIndex; i++) {
      if (!this.validateStep(i)) {
        (<any>this.$refs.bopSetupWizard).navigate(i);
        return false;
      }
    }
    this.setNavigationBehavour(stepIndex, nextStepIndex);
    // this.isOperationStepLoaded = false;
    // this.clearNotifications();
    return true;
  }
  private previous(stepIndex: number, nextStepIndex: number) {
    this.stepIndex = nextStepIndex;
    this.setNavigationBehavour(stepIndex, nextStepIndex);
    // this.isOperationStepLoaded = false;
    // this.clearNotifications();
    return true;
  }

  private notify(title: string, text: string, type: string) {
    const isError = type === "error";
    let notifObject: any = {
      group: isError ? "globalError" : "bopNotificationGroup",
      type,
      title,
      text,
    };
    if (type === "error") {
      notifObject.duration = Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS;
    }
    this.$notify(notifObject);
  }
  private globalCoefficients: Array<any> = [];
  /**
   * Validate the bop by checking if at least an operation is fully suplied in operation principal and diverse steps
   * return an error message and false when encountring the first:
   *      .Operation group without operation
   *      .an operation left blanck without selecting an operation
   *      .coefficient not suplied (left empty)
   */
  private validateBop(bop: Bop): boolean {
    let status = true;
    let message = "";

    // Validate pointValue for Tuyauterie Métallique
    if (
      this.isTuyauterieMetalique &&
      (!bop.pointValue || bop.pointValue <= 0)
    ) {
      status = false;
      message = this.$t("bopSetup.validationPointValueMissing").toString();
    }

    // Validating globalCoefficients
    let isGlobalCoefficientsSet = true;
    let isOperationPrincipalsSet = true;
    let isOperationDiversesSet = true;

    for (let i = 0; i < this.globalCoefficients.length; i++) {
      const coeffKey = this.globalCoefficients[i].key;
      const selectedGlobalCoefficients = this.bop.selectedGlobalCoefficients;
      if (!selectedGlobalCoefficients[coeffKey]) {
        isGlobalCoefficientsSet = false;
        break;
      }
    }
    if (status) {
      // Validating operation principal groups
      if (!bop.operationPrincipalGroups.length) {
        message = this.$t(
          "bopSetup.ValidationEmptyOperationsErrorMessage"
        ).toString();
        status = false;
      }
      for (let i = 0; i < bop.operationPrincipalGroups.length; i++) {
        let checkQuantity = false;

        const og: OperationGroup = bop.operationPrincipalGroups[i];
        if (og.canBeDeleted || og.isFourniture) continue;
        // if no operation were selected in a group
        if (og.operations.length === 0) {
          isOperationPrincipalsSet = false;
          break;
        } else {
          if (!status) break;
          for (let j = 0; j < og.operations.length; j++) {
            const operation: BopOperation = og.operations[j];
            checkQuantity = checkQuantity
              ? checkQuantity
              : operation.quantity > 0 ||
                operation.operation.operationFamilyKey === "viroles";
            //checking if there is an empty operation
            if (!operation.operationId) {
              status = false;
              message = this.$t(
                "bopSetup.summary.completePrincipalOperations"
              ).toString();
              break;
            } else {
              if (!status) break;
              //verifying if all the coefficients were suplied
              for (let k in operation.coefficientSelectedValues) {
                const coeff = operation.coefficientSelectedValues[k];
                if (
                  !coeff ||
                  (!coeff.coefficientOptionId &&
                    coeff.value !== 0 &&
                    !coeff.value)
                ) {
                  status = false;
                  message = this.$t(
                    "bopSetup.ValidationCoefficientErrorMessage"
                  ).toString();
                  break;
                }
              }
            }
          }
          if (!checkQuantity) {
            status = false;
            message = this.$t(
              "bopSetup.ValidationEmptyOperationsErrorMessage"
            ).toString();
          }
        }
        // if there is an error parse the error message to print the step and the group where the error is detected
        if (!status) {
          if (message.indexOf("{step}") !== -1) {
            message = message.replace(
              "{step}",
              this.$t("bop.operationPrincipal.stepTitle").toString()
            );
          }
          if (message.indexOf("{operationGroup}") !== -1) {
            message = message.replace(
              "{operationGroup}",
              og.operationFamilyName ??
                `${this.$t(
                  "bopSetup.operationPrincipal.operationGroup"
                ).toString()} ${i + 1}`
            );
          }
          break;
        }
      }
    }
    // do not validate the operation diverse step since we found errors in the operation principal step
    if (status) {
      //validating op diverse
      for (let i = 0; i < bop.operationDiverseGroups.length; i++) {
        const og: OperationGroup = bop.operationDiverseGroups[i];
        // if no operation diverse is selected
        if (og.operations.length === 0) {
          isOperationDiversesSet = false;
          break;
        } else {
          for (let j = 0; j < og.operations.length; j++) {
            const operation: BopOperation = og.operations[j];
            //checking if there is an empty operation
            if (!operation.operationId) {
              status = false;
              message = this.$t(
                "bopSetup.summary.completeDiverseOperations"
              ).toString();
              break;
            }
          }
        }
        // if there is an error parse the error message to print the step where the error is detected
        if (!status) {
          if (message.indexOf("{step}") !== -1) {
            message = message.replace(
              "{step}",
              this.$t("bop.operationDiverse.stepTitle").toString()
            );
          }
          break;
        }
      }
    }
    if (!isOperationPrincipalsSet && !isOperationDiversesSet) {
      message = this.$t(
        "bopSetup.ValidationEmptyOperationsErrorMessage"
      ).toString();
      status = false;
    } else if (isOperationPrincipalsSet && !isGlobalCoefficientsSet) {
      message = this.$t(
        "bopSetup.validationEmptyGlobalCoefficientsErrorMessage"
      ).toString();
    }
    if (!status) {
      this.notify(
        this.$t("bopSetup.validationErrorTitle").toString(),
        message,
        "error"
      );
    }
    return status;
  }

  private async exportBopPdf(): Promise<void> {
    if (Guid.parse(this.bop.id).isEmpty()) {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bopSetup.exportError.title").toString(),
        text: this.$t(
          "bopSetup.exportError.bopMustBeCreatedBeforeExport"
        ).toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
      return;
    }

    this.isExportingPdf = true;
    const bopId = this.bop.id;
    const bopNumber = this.bop.bopNumber;

    try {
      // Construct the BOP link dynamically
      const baseUrl = `${location.origin}/bops`;
      const bopLink = `${baseUrl}/${bopId}`;

      // Prepare the payload
      const generatePdfDto = {
        bopLink: bopLink,
        languageCode: this.$i18n.locale, // Assuming `this.$i18n.locale` contains the current language code
      };

      const response = await BopService.downloadPdf(bopId, generatePdfDto);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `BOP_${bopNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      this.$notify({
        group: "bopNotificationGroup",
        type: "success",
        title: this.$t("bopSetup.exportSuccess.title").toString(),
        text: this.$t("bopSetup.exportSuccess.message").toString(),
      });
    } catch (error) {
      console.error("Error exporting PDF:", error);
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t("bopSetup.exportError.title").toString(),
        text: this.$t("bopSetup.exportError.message").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    } finally {
      this.isExportingPdf = false;
    }
  }

  private onCaloTuyauterieOperationsChange(caloTuyauterieOperations) {
    this.caloTuyauterieOperations = caloTuyauterieOperations;
  }
  private mapValidationToBop() {
    if (this.isCreatedBySapAndEditable) {
      const validation = this.$store.state.validation;
      this.bop.sapEPIServiceNumber = validation.sapEPIServiceNumber;
      this.bop.sapPrestationServiceNumber =
        validation.sapPrestationServiceNumber;
      this.bop.sapSupplementServiceNumber =
        validation.sapSupplementServiceNumber;
      this.bop.imputationType = validation.imputationType;
      this.bop.purchaseOrder = validation.purchaseOrder;
      this.bop.otNumber = validation.order;
      this.bop.itemNumber = validation.itemNumber;
      this.bop.materialCost = validation.materialCost;
      this.bop.rentingCost = validation.rentingCost;
      this.bop.subcontractingCost = validation.subcontractingCost;
      this.bop.studyCost = validation.studyCost;
      this.bop.costCenter = validation.costCenter;
      this.bop.wbsElement = validation.WBSelement;
    }
  }

  //TODO Voir pour refacto les sauvegarde des processus SAP
  private async save(action?: string) {
    this.saveFinished = false;
    if (
      this.saveDisabled ||
      (!this.isEditable &&
        this.bop.bopStatusKey !== BopStatus.AwaitingLaunchWorkApproval)
    ) {
      return;
    }
    this.saveDisabled = true;
    const oldBopStatus = this.bop.bopStatusKey;

    try {
      let message = this.$t("bopSetup.saveSuccessMessage").toString();
      if (this.isCalorifugeageTuyauterieBop) {
        const operationGroups: Array<OperationGroup> = [];
        this.caloTuyauterieOperations.forEach((og) => {
          const operationGroup = new OperationGroup();
          operationGroup.number = og.number;
          operationGroup.id = og.id;
          for (let i in og.coefficientSelectedValues) {
            operationGroup.selectedGroupCoefficients[i] =
              og.coefficientSelectedValues[i].coefficientOptionId ??
              og.coefficientSelectedValues[i].value?.toString();
          }
          operationGroup.operations = Object.values(og.operations).map(
            (o: any) => ({
              ...o,
              coefficientSelectedValues: og.coefficientSelectedValues,
              operation: o,
            })
          );
          operationGroups.push(operationGroup);
        });
        this.bop.operationPrincipalGroups = operationGroups;
        this.$store.commit("changeBop", this.bop);
      }
      // Triggers on summary page
      if (this.canValidateBop) {
        if (!this.validateBop(this.bop)) {
          this.saveDisabled = false;
          this.saveFinished = true;
          return;
        }
        if (!this.bop.isCreatedBySap) {
          // Define the keys for travaux neufs and arrêts
          const travauxNeufsOrArretsKeys = ["travauxNeufs", "arrets"];
          if (action && action === "approve") {
            this.bop.bopStatusKey = BopStatus.AcceptLaunchWork;
          } else if (
            travauxNeufsOrArretsKeys.includes(
              this.typeTravaux.find((type) => type.id === this.bop.typeTravaux)
                ?.key
            )
          ) {
            this.bop.bopStatusKey = BopStatus.AwaitingLaunchWorkApproval;
          } else {
            this.bop.bopStatusKey = BopStatus.AcceptLaunchWork;
          }
        }
        message = this.$t("bopSetup.validationSuccessMessage").toString();
      }

      const saveBopResponse = await BopService.save(this.bop);

      // if its not created by sap or if its created by sap and the bop was send successufly
      let isSuccess = true;
      if (this.bop.isCreatedBySap && this.canValidateBop) {
        isSuccess =
          (
            await SapApiService.validateBop(
              this.bop.id,
              this.facturationValidation
            )
          ).status === 200;
        if (isSuccess) {
          this.bop.bopStatusKey = BopStatus.WaitingForValidation;
          this.notify(
            this.$t("bopSetup.sendForValidationSuccessTitle").toString(),
            this.$t("bopSetup.sendForValidationSuccessMessage").toString(),
            "success"
          );
        }
      }
      if (saveBopResponse.status === 200 && isSuccess) {
        this.saveDisabled = false;
        if (
          this.bop.bopStatusKey === BopStatus.AcceptLaunchWork &&
          this.facturationStepIndex === -1
        ) {
          this.steps.push(this.facturationStepConfig);
        }

        if (this.bop.id !== Guid.createEmpty().toString()) {
          await this.loadOperations(this.bop);
        }

        this.notify(
          this.$t("bopSetup.saveSuccessTitle").toString(),
          message,
          "success"
        );
        const bopId: string = saveBopResponse.data;
        this.saveFinished = true;
        this.$store.commit("changeHasBeenModified", false);
        if (
          this.bop.bopStatusKey === BopStatus.AcceptLaunchWork ||
          this.bop.bopStatusKey === BopStatus.AwaitingLaunchWorkApproval
        ) {
          await this.$router.push({
            name: "Accueil",
          });
        } else if (
          this.$route.params.bopId !== bopId ||
          this.stepIndex.toString() !== this.$route.query.index
        ) {
          this.$store.commit("changeHasBeenModified", false);
          const prevRouteName = this.$route.name;

          await this.$router.push({
            name: "BopSetupUpdate",
            params: { bopId },
            query: { index: this.stepIndex.toString() },
          });
          if (prevRouteName === "BopSetupUpdate") {
            this.startIndex = this.stepIndex;
            this.refreshWizard();
          }
        } else {
          this.startIndex = this.stepIndex;
          this.refreshWizard();
        }
      }
    } catch (err) {
      this.saveFinished = true;
      this.bop.bopStatusKey = oldBopStatus;
      this.saveDisabled = false;

      const detail = (err as { response: { data: { detail: string } } })
        .response.data.detail;

      const errorCodes: Array<string> = [
        "moreThan10Files",
        "fileExceedsMaxSize",
      ];

      let text = this.$t(`bop.saveErrorMessage`).toString();

      if (errorCodes.includes(detail)) {
        text = this.$t(`bop.errors.${detail}`).toString();
      } else if (detail === "febFieldsNotSpecified") {
        text = this.$t(`bop.errors.febMandatoryFieldsNotSpecified`).toString();
      }

      this.notify(this.$t(`bop.saveErrorTitle`).toString(), text, "error");
    }
  }

  private async declineBop() {
    const modalResponse = await this.$bvModal.msgBoxConfirm(
      router.app.$t("modal.cancelBopMessage").toString(),
      {
        title: router.app.$t("modal.cancelBopTitle").toString(),
        size: "lg",
        buttonSize: "lg",
        okVariant: "primary",
        cancelVariant: "secondary",
        okTitle: router.app.$t("modal.cancelBopYes").toString(),
        cancelTitle: router.app.$t("modal.cancelBopNo").toString(),
        dialogClass: "modal-app",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      }
    );
    if (modalResponse) {
      try {
        const bopId = this.bop.id;
        const response = await BopService.cancelBop(bopId);
        await this.$router.push({
          name: "Accueil",
        });
        if (response.status === 200) {
          this.bop.bopStatusKey = BopStatus.Declined;
          this.bop.bopStatusName = this.statuses[BopStatus.Declined];
          this.$notify({
            group: "globalError",
            type: "success",
            title: this.$t("bopSetup.declineSuccessTitle").toString(),
            text: this.$t("bopSetup.declineSuccessMessage").toString(),
            duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
          });
        }
      } catch {}
    }
  }

  private get statuses(): Object {
    return this.$store.state.statuses;
  }

  private async approveBop() {
    await this.save("approve");
  }

  private async loadOperations(bop: Bop) {
    if (
      bop.bopStatusKey !== BopStatus.AwaitingLaunchWorkApproval &&
      Bop.readOnlyStatuses.includes(bop.bopStatusKey) &&
      !this.isEdit
    ) {
      const bopArchive = (await BopArchiveService.getBopArchive(bop.id)).data;
      if (!bopArchive) return;

      bop.operationDiverseGroups = bopArchive.operationDiverseGroups ?? [
        new OperationGroup(),
      ];
      bop.operationPrincipalGroups = bopArchive.operationPrincipalGroups;
      bop.operationSuppGroups = bopArchive.operationSuppGroups;
      this.bop.selectedGlobalCoefficients =
        bopArchive.selectedGlobalCoefficients;
      this.bop.heightSupplements = bopArchive.heightSupplements;
      this.operationPrinciaplLoaded = true;
      return;
    }
    const operationPrincipalGroupsResponse = await BopService.getBopOperationGroups(
      bop.id,
      OperationType.OperationPrincipal,
      this.language
    );
    if (operationPrincipalGroupsResponse.status === 200) {
      this.operationPrinciaplLoaded = true;
      bop.operationPrincipalGroups = operationPrincipalGroupsResponse.data;
    }

    // loading operation diverse
    bop.operationDiverseGroups = (
      await BopService.getBopOperationGroups(
        bop.id,
        OperationType.OperationDiverse,
        this.language
      )
    ).data;
    bop.operationDiverseGroups = bop.operationDiverseGroups.length
      ? bop.operationDiverseGroups
      : [new OperationGroup()];

    // loading operation supp
    bop.operationSuppGroups = (
      await BopService.getBopOperationGroups(
        bop.id,
        OperationType.OperationSupplementaire,
        this.language
      )
    ).data;
    bop.operationSuppGroups = bop.operationSuppGroups.length
      ? bop.operationSuppGroups
      : [new OperationGroup()];
  }

  private async loadData() {
    // console.group("im being laoded");
    this.steps = [
      {
        slot: "generalInformations",
        title: this.$t("bop.generalInformations.stepTitle").toString(),
        requiredFieldsKeys: [
          {
            translationKey: "bopSetup.bopName",
            elementKey: "name",
            isRequiredOnSimulation: true,
            validation: null,
          },
          {
            translationKey: "bopSetup.sites",
            elementKey: "siteId",
            isRequiredOnSimulation: false,
            validation: null,
          },
          {
            translationKey: "bopSetup.ordergivers",
            elementKey: "orderGiverUserId",
            isRequiredOnSimulation: false,
            validation: null,
          },
          {
            translationKey: "bopSetup.entreprises",
            elementKey: "entrepriseId",
            isRequiredOnSimulation: false,
            validation: null,
          },
          {
            translationKey: "bopSetup.bopModelChoice",
            elementKey: "bopModelId",
            isRequiredOnSimulation: true,
            validation: null,
          },
        ],
      },

      {
        slot: "operationPrincipal",
        title: this.$t("bop.operationPrincipal.stepTitle").toString(),
        requiredFieldsKeys: [],
      },
      {
        slot: "operationDiverse",
        title: this.$t("bop.operationDiverse.stepTitle").toString(),
        requiredFieldsKeys: [],
      },
      {
        slot: "operationSupp",
        title: this.$t("bop.operationSupp.stepTitle").toString(),
        requiredFieldsKeys: [],
      },
      {
        slot: "summary",
        title: this.$t("bop.summary.stepTitle").toString(),
        requiredFieldsKeys: [],
        // btnSaveText: this.$t("wizard.validate").toString()
      },
    ];
    this.typeTravaux = (
      await TypeTravauxService.listTypeTravaux(this.language)
    ).data;

    if (this.bop.bopModelKey === BopModelEnum.Echafaudage) {
      this.steps.splice(1, 0, this.febStepConfig);
    }
    const bopStatuses = (await BopStatusService.getAllStatuses(this.language))
      .data;
    this.$store.commit("loadBopStatuses", bopStatuses);

    this.$store.commit("changeBopOperationOptions", null);
    this.$store.commit("changeSelectedGlobalCoefficients", {});
    const bopId: any = this.$route.params.bopId;
    let validation: Validation = new Validation();
    if (bopId) {
      let bop: Bop;
      if (isNaN(bopId)) {
        bop = (await BopService.getBop(bopId)).data;
      } else {
        bop = (await BopService.getBopByNumber(bopId)).data;
      }
      this.$store.commit("changeBop", bop);
      if (bop.bopModelId != null) {
        const bm = (
          await BopModelService.getBopModel(bop.bopModelId, this.language)
        ).data;
        this.$store.commit("changeSelectedBopModel", bm);
        this.globalCoefficients = (
          await CoefficientService.getCoefficientByTypeAndBopModel(
            bop.bopModelId,
            CoefficientTypeEnum.T4,
            this.language
          )
        ).data;
      }

      //loading operations principal
      await this.loadOperations(bop);

      this.$store.commit("changeBop", bop);
      if (this.bop.isCreatedBySap) {
        this.steps.splice(
          this.steps.findIndex((s) => s.slot === "operationSupp") + 1,
          0,
          this.facturationSapStepConfig
        );
      }

      if (
        this.bop.bopStatusKey === BopStatus.AcceptLaunchWork ||
        this.bop.bopStatusKey === BopStatus.Finished ||
        this.bop.bopStatusKey === BopStatus.Archived
      ) {
        this.steps.push(this.facturationStepConfig);
      }
    } else {
      this.operationPrinciaplLoaded = true;
    }

    this.$store.commit("changeValidation", validation);
    // console.groupEnd();
  }

  async canApproveBop(): Promise<boolean> {
    const bopId = this.bop.id;
    return await userService.canApproveBop(bopId, this.currentUser.id);
  }
  /**
   * ######
   * Hooks
   * ######
   */

  private async created() {
    this.loadData().then(() => {
      if (!this.$store.state.selectedBopModel && this.bop.bopModelId) {
        BopModelService.getBopModel(this.bop.bopModelId, this.language).then(
          (response) => {
            const bm = response.data;
            this.$store.commit("changeSelectedBopModel", bm);
          }
        );
      }

      if (this.$route.query.index) {
        let stepIndex = parseInt(this.$route.query.index.toString());
        if (!isNaN(stepIndex) && stepIndex < this.steps.length) {
          // steps validation
          let isValidatePreviousSteps = true;
          for (let i = 0; i <= stepIndex; i++) {
            if (!this.validateStep(i, false)) {
              isValidatePreviousSteps = false;
              stepIndex = i;
              break;
            }
          }

          this.startIndex = stepIndex;
          this.stepIndex = stepIndex;
          if (
            this.canValidateBop ||
            (this.bop.isCreatedBySap && stepIndex === this.facturationStepIndex)
          ) {
            this.saveButtonText = this.$t("wizard.validate").toString();
          }
        }
        this.isLoaded = true;
        this.$nextTick().then(() => {
          setTimeout(() => {
            this.$store.commit("changeHasBeenModified", false);
            this.isVisible = true;
          }, 200);
        });
      } else {
        this.isVisible = true;
        this.isLoaded = true;
      }
    });
  }
  private destroyed() {
    this.$store.commit("changeBop", new Bop());
    this.$store.commit("changeSelectedBopModel", null);
  }
  /**
   * #####
   * Watchers
   * #####
   */
  @Watch("bop.id")
  async onBopIdChange(newBopId: string) {
    if (!newBopId || newBopId === Guid.createEmpty().toString()) {
      return;
    }

    console.log("BOP is loaded with ID:", newBopId);
    this.userCanApproveBop = await this.canApproveBop();
  }
  @Watch("bop.bopModelKey")
  onBopModelKeyChange() {
    if (this.bop.bopModelKey === BopModelEnum.Echafaudage) {
      this.steps.splice(1, 0, this.febStepConfig);
    } else {
      const febSlotIndex = this.steps.findIndex(
        (s) => s.slot === this.febStepConfig.slot
      );
      if (febSlotIndex !== -1) {
        this.steps.splice(febSlotIndex, 1);
      }
    }
    this.$store.commit("changeHasBeenModified", true);
  }
  @Watch("language")
  private onLanguageChange() {
    this.isLoaded = false;
    this.loadData().then(() => {
      this.startIndex = this.stepIndex ?? 0;
      if (this.bop.bopModelId) {
        BopModelService.getBopModel(this.bop.bopModelId, this.language).then(
          (response) => {
            const bm = response.data;
            this.bop.bopModelName = bm.name;
            this.$store.commit("changeHasBeenModified", false);
          }
        );
      }
      if (this.bop.siteId) {
        SitesServices.getTranslatedSite(this.bop.siteId, this.language).then(
          (response) => {
            const site = response.data;
            this.bop.siteName = site.translation.name;
            this.$store.commit("changeHasBeenModified", false);
          }
        );
      }
      this.isLoaded = true;
      this.$nextTick().then(() => {
        setTimeout(() => {
          this.$store.commit("changeHasBeenModified", false);

          this.isVisible = true;
        }, 200);
      });
    });
  }
}
