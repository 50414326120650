import apiProvider from "@/utils/api";
import axios, { AxiosPromise, AxiosResponse } from "axios";
import { toUrl } from "@/utils/helpers";
import Bop from "@/models/Bop";
import language from "@/i18n";
const rootPath = "bops";

import i18n from "@/i18n";
export type GeneratePdfDto = {
  bopLink: string;
  languageCode: string;
};
class BopService {
  // public getTableUrl(lang:string): string {
  //     return `${apiProvider.getUrl()}${rootPath}${toUrl({lang: lang})}`;
  // }

  public getBop(bopId: string): AxiosPromise {
    return apiProvider.get(
      `${rootPath}/${bopId}${toUrl({ lang: language.locale })}`
    );
  }
  public getBopByNumber(bopNumber: string): AxiosPromise {
    return apiProvider.get(
      `${rootPath}/byNumber/${bopNumber}${toUrl({ lang: language.locale })}`
    );
  }
  public getCurrrentTotalPoint(bopId: string): AxiosPromise {
    return apiProvider.get(
      `${rootPath}/currenttotal/${bopId}${toUrl({ lang: language.locale })}`
    );
  }
  public getBopOperationGroups(
    bopId: string,
    operationType: number,
    lang: string
  ): AxiosPromise {
    return apiProvider.get(
      `${rootPath}/${bopId}/operationGroups${toUrl({ operationType, lang })}`
    );
  }

  public save(
    bop: Bop,
    canBeEditedAfterRealised: boolean = false
  ): AxiosPromise {
    return apiProvider.put(
      `${rootPath}/${bop.id}${toUrl({
        canBeEditedAfterRealised,
        lang: language.locale,
      })}`,
      bop
    );
  }

  public getSummaryUrl(lang: string): string {
    return `${apiProvider.getUrl()}${rootPath}/bopSummary/${lang}`;
  }

  public getAllBopUrl(lang: string): string {
    return `${apiProvider.getUrl()}${rootPath}/all/${lang}`;
  }

  public async getAllBop(lang: string): Promise<any> {
    return apiProvider.get(this.getAllBopUrl(lang));
  }

  public async getBopSummaries(lang: string): Promise<any> {
    return apiProvider.get(this.getSummaryUrl(lang));
  }
  public async setBopToInitialState(bopId: string): Promise<any> {
    return apiProvider.patch(
      `${apiProvider.getUrl()}${rootPath}/${bopId}/setInitialState`
    );
  }
  public userHaveAccess(bopIdOrNumber: string): Promise<any> {
    return apiProvider.get(
      `${apiProvider.getUrl()}${rootPath}/userHaveAccess/${bopIdOrNumber}`
    );
  }

  public cancelBop(bopId: string): Promise<any> {
    return apiProvider.post(
      `${apiProvider.getUrl()}${rootPath}/cancelBop`,
      JSON.stringify(bopId)
    );
  }

  public mailDismantleBop(bopId: string): Promise<any> {
    return apiProvider.post(
      `${apiProvider.getUrl()}${rootPath}/mailDismantleBop${toUrl({
        lang: language.locale,
      })}`,
      JSON.stringify(bopId)
    );
  }

  public generatePdf(
    generatePdfDto: GeneratePdfDto,
    bopId: string
  ): Promise<AxiosResponse<any>> {
    return apiProvider.post(
      `${apiProvider.getUrl()}${rootPath}/generate-pdf/${bopId}`,
      generatePdfDto
    );
  }

  public downloadPdf(
    bopId: string,
    generatePdfDto: GeneratePdfDto
  ): Promise<AxiosResponse<any>> {
    return apiProvider.post(
      `${apiProvider.getUrl()}${rootPath}/download-bop-pdf/${bopId}`,
      generatePdfDto,
      { responseType: "blob" }
    );
  }
}

export default new BopService();
