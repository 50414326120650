import { BopStatus } from "@/utils/Enums";
import { Guid } from "guid-typescript";
import ComplementaryData from "./ComplementaryData";
import Feb from "./Feb";
import OperationGroup from "./OperationGroup";
import Visa from "./Visa";
import File from "./File";
import HeightSupplement from "./HeightSupplement";

export default class Bop {
  public id: string;
  public isSimulation: boolean;
  public isCreatedBySap: boolean;
  public name: string;
  public bopNumber: number | undefined;
  public otNumber: string;
  public createdDate: Date;
  public siteId: string | null;
  public orderGiverUserId: string | null;
  public interventionPlace: string;
  public equipement: string;
  public totalOperationDiversePoints: number;
  public totalOperationPrincipalPoints: number;
  public totalOperationSuppHeurePoints: number;
  public totalOperationSuppEpiPoints: number;
  public totalSuppHauteurPoints: number;
  public totalPoints: number;
  public entrepriseId: string | null;
  public providerMails: string;
  public startDate: Date | null;
  public endDate: Date | null;
  public bopModelId: string | null;
  public bopModelKey: string;
  public bopModelName: string;
  public bopStatusKey: string;
  public bopStatusName: string;
  public siteName: string;
  public sapPrestationServiceNumber: string;
  public sapSupplementServiceNumber: string;
  public sapEPIServiceNumber: string;
  public wbsElement: string;
  public order: string;
  public purchaseOrder: string;
  public itemNumber: string;
  public imputationType: string;
  public costCenter: string;
  public materialCost: number;
  public rentingCost: number;
  public subcontractingCost: number;
  public studyCost: number;
  public typeTravaux: string | null;
  // each set of operations is contained in an operation group
  public operationDiverseGroups: Array<OperationGroup>;
  public operationPrincipalGroups: Array<OperationGroup>;
  public operationSuppGroups: Array<OperationGroup>;
  public complementaryData: null | ComplementaryData;
  public heightSupplements: Array<HeightSupplement>;
  public selectedGlobalCoefficients: any;
  public feb: Feb | null;
  public visas: Array<Visa>;
  public files: Array<File>;
  public deletedOperations: Array<string>;
  public daNumber: string;
  public itemDA: string;
  public pointValue: number | null;
  constructor() {
    this.id = Guid.createEmpty().toString();
    this.isSimulation = false;
    this.isCreatedBySap = false;
    this.name = "";
    this.bopNumber = undefined;
    this.otNumber = "";
    this.createdDate = new Date();
    this.siteId = null;
    this.orderGiverUserId = null;
    this.interventionPlace = "";
    this.equipement = "";
    this.entrepriseId = null;
    this.providerMails = "";
    this.startDate = null;
    this.endDate = null;
    this.bopModelId = null;
    this.bopModelKey = "";
    this.bopModelName = "";
    this.bopStatusKey = BopStatus.Initial;
    this.bopStatusName = "";
    this.siteName = "";
    this.itemNumber = "";
    this.wbsElement = "";
    this.costCenter = "";
    this.order = "";
    this.imputationType = "";
    this.materialCost = 0;
    this.purchaseOrder = "";
    this.rentingCost = 0;
    this.sapEPIServiceNumber = "";
    this.sapPrestationServiceNumber = "";
    this.sapSupplementServiceNumber = "";
    this.studyCost = 0;
    this.subcontractingCost = 0;
    this.typeTravaux = null;
    this.operationDiverseGroups = [new OperationGroup()];
    this.operationPrincipalGroups = [];
    this.operationSuppGroups = [new OperationGroup()];
    this.totalOperationDiversePoints = 0;
    this.totalOperationPrincipalPoints = 0;
    this.totalOperationSuppHeurePoints = 0;
    this.totalOperationSuppEpiPoints = 0;
    this.totalSuppHauteurPoints = 0;
    this.totalPoints = 0;
    this.complementaryData = null;
    this.selectedGlobalCoefficients = {};
    this.feb = null;
    this.visas = [];
    this.files = [];
    this.heightSupplements = [];
    this.deletedOperations = [];
    this.daNumber = "";
    this.itemDA = "";
    this.pointValue = null;
  }
  public static get readOnlyStatuses(): Array<string> {
    return [
      BopStatus.AcceptLaunchWork,
      BopStatus.AwaitingLaunchWorkApproval,
      BopStatus.Archived,
      BopStatus.Finished,
      BopStatus.Declined,
    ];
  }
}
